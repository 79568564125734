import React, { useEffect } from 'react'
import { useAuthentication, rememberLocation } from '@src/context/Authentication'
import { API_URL } from '@src/utils'

export default (Loading, Comp) => {
  return props => {
    const { user, loading } = useAuthentication()

    useEffect(() => {
      if (!loading && !user) {
        rememberLocation()

        const { protocol, host } = window.location
        const back = `${protocol}//${host}`
        window.location.replace(`${API_URL}/api/login?host=${encodeURIComponent(back)}`)
      }
    }, [user, loading])

    return user ? <Comp {...props} /> : <Loading />
  }
}
