import React from 'react'
import { MainLayout as Main } from '@cmp/site'
import { Container, Row, Col } from '@kogk/common'
import { GlobalDataProvider } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import LoadingPage from '@src/components/templates/LoadingPage'
import requiresLogin from '@src/components/site/RequiresLogin/RequiresLogin'
import { useAuthentication } from '@src/context/Authentication'

// example page that uses the login - can be deleted

export default requiresLogin(LoadingPage, () => {
  const { language } = useLanguage()
  const { user } = useAuthentication()
  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <Main className='d-flex'>
        <Container>
          <Row>
            <Col>
              <h2>
                Velkomin/n/ið
                <br />
                {user.name}
              </h2>
            </Col>
          </Row>
        </Container>
      </Main>
    </GlobalDataProvider>
  )
})
